

<template>
  <div></div>
</template>

<script>
export default {
    created(){
        localStorage.setItem("user", JSON.stringify({jwt: window.location.href?.split("autologin/")[1]}))
        setTimeout(() => {
            localStorage.setItem("user", JSON.stringify({jwt: window.location.href?.split("autologin/")[1]}))
            if(window.location.href.includes("?")){
                localStorage.setItem("user", JSON.stringify({jwt: window.location.href.split("?=")[1]}))
            }
            window.location = '/'
        }, 1000);
    }
}
</script>

<style>

</style>